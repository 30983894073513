import React from 'react'
import CabaretLayout from '../../components/cabaretlayout'
import CabaretCandidats from '../../slices/CabaretCandidats'

const CabaretProgrammation = () => (
    <CabaretLayout>
        <div>

            <CabaretCandidats className='!hidden' />

            <div className='cabaret-lescandidats-old text-center'>
                <h1>Les anciens candidat·es</h1>
                <div className='flex flex-wrap md:mx-36 mx-0'>
                <div className='md:w-1/4 w-full py-2'>
                        <h2>2024</h2>
                        <h6>Oui Merci</h6>
                        <h6>Oliver Forest</h6>
                        <b><h6>Anaïs Constantin (Prix du Jury)</h6></b>
                        <h6>Sloan Lucas</h6>
                        <h6>Corail</h6>
                        <h6>Halde</h6>
                        <b><h6>Bermuda (Prix du Public)</h6></b>
                        <h6>Fovelle</h6>
                        <h6>Olivier Lessard</h6>
                        <h6>Madame Autruche</h6>
                        <h6>Cure-Pipe</h6>
                        <h6>Coco Rose</h6>
                    </div>
                    <div className='md:w-1/4 w-full py-2'>
                        <h2>2023</h2>
                        <h6>2120</h6>
                        <h6>Antoine Aspirine</h6>
                        <h6>Maude Saunier</h6>
                        <b><h6>Charlotte Brousseau (Prix du Public)</h6></b>
                        <h6>Bourbon</h6>
                        <b><h6>Embo/phlébite (Prix du Jury)</h6></b>
                        <h6>LYD.</h6>
                        <h6>Sandra Contour</h6>
                        <h6>Lilas l'bon temps</h6>
                        <h6>Pataugeoire</h6>
                        <h6>Cayenne</h6>
                        <h6>Anne Painchaud</h6>
                    </div>
                    <div className='md:w-1/4 w-full py-2'>
                        <h2>2022</h2>
                        <h6>Loïc Lafrance</h6>
                        <h6>Gawbé</h6>
                        <b><h6>Homebrew Remedy (Prix du Public)</h6></b>
                        <h6>Caractère Gras</h6>
                        <h6>Pastel Barbo</h6>
                        <h6>Jeanne Côté</h6>
                        <b><h6>Malaimé Soleil (Prix du Jury)</h6></b>
                        <h6>Soraï</h6>
                        <h6>Après L’Asphalte</h6>
                        <h6>Jérome Casabon.</h6>
                        <h6>Saligaude</h6>
                        <h6>Michaëlle Richer</h6>
                    </div>
                    <div className='md:w-1/4 w-full py-2'>
                        <h2>2020</h2>
                        <b><h6>Ariane Roy (Prix du Public)</h6></b>
                        <h6>Guillaume Bordel</h6>
                        <h6>Dany Nicolas</h6>
                        <h6>Blanc Manger</h6>
                        <h6>Duu</h6>
                        <h6>Mélodie Spear</h6>
                        <h6>Ben Claveau</h6>
                        <h6>Titelaine</h6>
                        <h6>Le.Panda</h6>
                        <b><h6>Foisy. (Choix du Jury)</h6></b>
                        <h6>Les Royal Pickles</h6>
                        <h6>Belle Grand Fille</h6>
                    </div>
                    <div className='md:w-1/4 w-full py-2'>
                        <h2>2019</h2>
                        <b><h6>Alex Burger (Prix du Jury)</h6></b>
                        <h6>Antoine Lachance</h6>
                        <h6>Bourbon</h6>
                        <b><h6>Comment Debord (Prix du Public)</h6></b>
                        <h6>Éli Doyon et la Tempête</h6>
                        <h6>Fri Moeras</h6>
                        <h6>Georges Ouel</h6>
                        <h6>JP Couet</h6>
                        <h6>Kirouac & Kodakludo</h6>
                        <h6>Laura Lefebvre</h6>
                        <h6>Marie-Gold</h6>
                        <h6>Patrick Bourdon</h6>
                    </div>
                    <div className='md:w-1/4 w-full py-2'>
                        <h2>2018</h2>
                        <h6>Artifice Palace</h6>
                        <h6>Caterino</h6>
                        <h6>De la reine</h6>
                        <b><h6>É-T-É (Prix du Public)</h6></b>
                        <b><h6>Étienne Fletcher (Prix du Jury)</h6></b>
                        <h6>Jessy Benjamin</h6>
                        <h6>Joey Robin Haché</h6>
                        <h6>Laura Babin</h6>
                        <h6>Les monsieurs</h6>
                        <h6>Natation</h6>
                        <h6>Perdrix</h6>
                        <h6>The Johans</h6>
                    </div>
                    <div className='md:w-1/4 w-full py-2'>
                        <h2>2017</h2>
                        <h6>A Leverage for Mountains</h6>
                        <h6>Doloréanne</h6>
                        <b><h6>Émile Gruff (Prix du Public)</h6></b>
                        <h6>Jérome Casabon</h6>
                        <h6>Jérome St-Kant</h6>
                        <h6>La Guillaumansour expérience</h6>
                        <h6>La Valérie</h6>
                        <h6>Lumière</h6>
                        <h6>MCC</h6>
                        <h6>Mélanie Venditti</h6>
                        <b><h6>Miss Sassoeur et les Sassys (Prix du Jury)</h6></b>
                        <h6>Vice E Roi</h6>
                    </div>
                    <div className='md:w-1/4 w-full py-2'>
                        <h2>2016</h2>
                        <h6>Samuele</h6>
                        <b><h6>Marco et les torvis (Prix du Public)</h6></b>
                        <h6>Lucil</h6>
                        <b><h6>Gab Paquet (Prix du Jury)</h6></b>
                        <h6>Le Winston band</h6>
                        <h6>Blond Cerise</h6>
                        <h6>Lydia Képinski</h6>
                        <h6>Harfang</h6>
                        <h6>Maxime Auguste</h6>
                        <h6>David et les playboys</h6>
                        <h6>Mathilde Laurier</h6>
                        <h6>Mat Vezio</h6>
                    </div>
                    <div className='md:w-1/4 w-full py-2'>
                        <h2>2015</h2>
                        <h6>Caltâr-Bateau</h6>
                        <h6>Antonin Gilbert</h6>
                        <h6>Gabrielle Shonk</h6>
                        <b><h6>Émile Bilodeau (Prix du Jury)</h6></b>
                        <h6>Pierre-Hervé Goulet</h6>
                        <h6>The Robert’s Creek Saloon</h6>
                        <h6>Clay and Friends</h6>
                        <h6>Mathieu Bérubé</h6>
                        <h6>Les Guerres dl’Amour</h6>
                        <h6>Jean-François Malo</h6>
                        <h6>Beat Sexü</h6>
                        <b><h6>Sweet Grass (Prix du Public)</h6></b>
                    </div>
                    <div className='md:w-1/4 w-full py-2'>
                        <h2>2014</h2>
                        <h6>Charabia</h6>
                        <h6>Sara Dufour</h6>
                        <h6>Macédoine</h6>
                        <h6>Robert Fusil</h6>
                        <h6>Cherry Chérie</h6>
                        <h6>Francis Belmont</h6>
                        <h6>Odile Dupont</h6>
                        <b><h6>Michel Robichaud (Prix du Jury)</h6></b>
                        <h6>Anthony Roussel</h6>
                        <b><h6>Orloge Simard (Prix du Public)</h6></b>
                        <h6>Ragoût</h6>
                    </div>
                    <div className='md:w-1/4 w-full py-2'>
                        <h2>2013</h2>
                        <b><h6>Les Gars à’ Botch (Prix du Public)</h6></b>
                        <h6>Chambarde</h6>
                        <h6>Cinq-mars</h6>
                        <h6>Eldéanne</h6>
                        <b><h6>Garoche ta sacoche (Prix du Jury)</h6></b>
                        <h6>Les inclassables</h6>
                        <h6>Pico Larouche</h6>
                        <h6>Claudia Thibault Lebreux</h6>
                        <h6>Simon Kearney</h6>
                        <h6>Moscaille</h6>
                        <h6>Labrie</h6>
                    </div>
                </div>
            </div>
        </div>

    </CabaretLayout>
)

export default CabaretProgrammation
