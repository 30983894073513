import React from 'react'
import { allCabaretCandidats, getCabaretCandidatBySlug } from '../data/cabaret-data'
import { DynamicStaticImage } from '../components/ArtistAtSchool/dynamicStaticImage'

const Candidat = ({ slug }) => {
  const candidat = getCabaretCandidatBySlug(slug)
  return (
    <div className='flex justify-center'>
      <a href={`/cabaret/lescandidats/${candidat.slug}`}>
        <div className='w-72 max-h-48 overflow-hidden rounded-xl transition-transform transform hover:scale-105'>
          <DynamicStaticImage localPath={candidat.getImageLocalPath} alt={candidat.name} />
        </div>
        <div className='cabaret-candidat-name'>
          <h3 className='mt-2'>{candidat.name}</h3>
        </div>
      </a>
    </div>
  )
}

const CabaretCandidats = ({ className }) => {
  return (
    <div className={`my-12 md:mx-36 mx-9 ${className}`}>
      <div className=''>
        <div className='cabaret-page-title mb-4'>
          <h1>Les candidat·es 2024</h1>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-4 justify-items-center text-center max-w-[1000px] mx-auto">
          {allCabaretCandidats.map((candidat) => (
            <div key={candidat.slug} className="mb-4 md:mb-0">
              <Candidat slug={candidat.slug} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CabaretCandidats
